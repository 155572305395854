.App {
	text-align: center;
	padding-top: 50px;
}

.App-logo {
	height: 40vmin;
}

* {
	box-sizing: border-box;
}

.App-header {
	background-color: #282c34;
	min-height: 50vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #09d3ac;
}

body {
	font-family: Arial, Helvetica, sans-serif;
}

header {
	background-color: #666;
	padding: 30px;
	text-align: center;
	font-size: 35px;
	height: 10%;
	color: white;
}

user {
	float: left;
	width: 5%;
	height: 90%;
	background: #ccc;
	padding: 20px;
}

user ul {
	list-style-type: none;
	padding: 0;
}

main {
	float: left;
	padding: 20px;
	width: 95%;
	height: 90%;
	background-color: #f1f1f1;
}

section:after {
	content: "";
	display: table;
	clear: both;
}

.LoginStyle {
	background-color: #fcfcfc;
	height: 100vh;
}

.SantaDiv {
	background-color: #666;
	padding: 30px;
	text-align: center;
	font-size: 35px;
	height: 10%;
	color: white;
	/* background-image: url('../src/santa1.jpeg'); */
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	background: white url('../src/img/santa2.jpeg') center center no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #282c34;
}

.center-fit {
	max-width: 100%;
	max-height: 100vh;
	margin: auto;
}
.imgbox {
	display: grid;
	height: 100%;
}
.imglink {
	display: grid;
	height: 100px;
	box-shadow: 5px 5px 10px #888888;
}

.container {
	padding: 30px;
}

ul.images {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-gap: 15px;
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.images > li {
	position: relative;
	padding-top: 66%;
}

ul.images > li img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	object-fit: cover;
}

@media (max-width: 567px) {
	ul.images {
		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	}
}

button.fullscreen-toggle.toggle--right {
	outline: none;
}

button.fullscreen-toggle.toggle--left {
	outline: none;
}

button.fullscreen-exit-btn {
	margin-top: 40px;
}
